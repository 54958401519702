














import { Vue, Component } from "vue-property-decorator";
import PieChart from "@/views/admin/subComponents/PieChart.vue";
import { ReportModule } from "@/store/modules";
import { IncidentStatus } from "../../../utils/constants";

@Component({
  components: { PieChart }
})
export default class AdminFilters extends Vue {
  
  get Incidents() {
    return ReportModule.incidents;
  }
 
  get p2Data() {
    let val1 = 0;
    let val2 = 0;
    let val3 = 0;

    const a = ReportModule.reportByStatus.filter(
      x => x.Status == IncidentStatus.Completed
    );

    const b = ReportModule.reportByStatus.filter(
      x => x.Status == IncidentStatus.Rejected
    );

    const c = ReportModule.reportByStatus.filter(
      x => x.Status == IncidentStatus.Failed
    );
    if (a.length > 0) {
      val1 = a[0].Count;
    }
    if (b.length > 0) {
      val2 = b[0].Count;
    }

    if (c.length > 0) {
      val3 = c[0].Count;
    }

    return [
      { name: "New", y: this.TotalNotAssignedIncidents },
      { name: "Assigned", y: this.TotalAssignedIncidents },
      { name: "Acknowledged", y: this.TotalAcknowlededIncidents },
      { name: "Completed", y: this.TotalCompletedIncidents },
      { name: "Failed", y: this.TotalFailedIncidents },
      { name: "Rejected", y: this.TotalRejectedIncidents }
    ];
  }

  get TotalNotAssignedIncidents() {
    return this.Incidents.filter(x => x.Status == IncidentStatus.Created)
      .length;
  }
  get TotalAssignedIncidents() {
    return this.Incidents.filter(x => x.Status == IncidentStatus.Assigned)
      .length;
  }
  get TotalAcknowlededIncidents() {
    return this.Incidents.filter(x => x.Status == IncidentStatus.Acknowledge)
      .length;
  }

  get TotalCompletedIncidents() {
    return this.Incidents.filter(x => x.Status == IncidentStatus.Completed)
      .length;
  }
  get TotalFailedIncidents() {
    return this.Incidents.filter(x => x.Status == IncidentStatus.Failed).length;
  }
  get TotalRejectedIncidents() {
    return this.Incidents.filter(x => x.Status == IncidentStatus.Rejected)
      .length;
  }
}
