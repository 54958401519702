













































import { Vue, Component } from "vue-property-decorator";
import { IncidentModule, ReportModule } from "@/store/modules";
import { IncidentStatus } from "@/utils/constants";
import RuleViolation from "@/store/modules/ruleViolationChart"

@Component({
  components: {},
})
export default class AdminCards extends Vue {

  async created(){
    await RuleViolation.ViolationChart()
  }

  get registeredVehicles() {
    return RuleViolation.chart.registeredVehicles;
  }
  get totalRoutes() {
    return RuleViolation.chart.noOfRoutes;
  }
  get totalSpeedViolationToday() {
    return RuleViolation.chart.speedViolationToday;
  }
  get totalRouteViolationToday() {
    return RuleViolation.chart.routeViolationToday;
  }
}
