




























import { Vue, Component } from "vue-property-decorator";
import CommitChart from "@/views/admin/subComponents/BarChart.vue";
import { ReportModule } from "../../../store/modules";
import * as d3 from "d3";

@Component({
  components: { CommitChart }
})
export default class AdminFilters extends Vue {
  incidents: any[] = [];
  mounted() {
   
  }

  get incidentReportLabelByCategory() {
    return  ReportModule.keyValueIncidents.map(x => x.name);
  }

  get incidentReportDataByCategory() {
    return ReportModule.keyValueIncidents;
  }
  get incidentReportLabelByWeekly() {


    return ReportModule.weeklyIncidentData.map(x => x.name);
  }
  get incidentReportDataByWeekly() {
   
    return ReportModule.weeklyIncidentData.map(x => x.y);
  }
}
