import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";


export default new class RuleViolationChartAPI {
    public async getViolationChart(): Promise<any> {
        const action = Action.getViolationChart
        const response = await getApi(action);
        return response as any
    }
}