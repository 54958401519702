








import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Highcharts from "highcharts";
import loadExporting from "highcharts/modules/exporting";
@Component({
  components: {}
})
export default class PieChart extends Vue {
  @Prop()
  elId!: string;
  @Prop()
  chartTitle!: string;

  @Prop()
  labels!: string[];

  @Prop()
  graphData!: number[];

  colors: string[] = [];

  mounted() {
    // Overwriting base render method with actual data.
    this.renderPieChart();
  }
  dataLen() {
    const d = this.graphData as any[];
    if (parseInt(d[0].y)=== 0 && parseInt(d[1].y) === 0 && parseInt(d[2].y) === 0) return true;
    else return false;
  }
  renderPieChart() {
    var that = this;
    this.colors = this.graphData.map(x => that.radomColor());
    const series: any = [
      {
        colorByPoint: true,
        data: this.graphData
      }
    ];

    const chart: any = {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie"
    };
    loadExporting(Highcharts);
    Highcharts.chart(this.elId, {
      chart: chart,
      title: {
        text: that.chartTitle
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: series as any,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom"
              }
            }
          }
        ]
      },
      credits: {
        enabled: false
      }
    });
  }
  @Watch("graphData")
  onlabelsChanged(val: string, oldVal: string) {
    this.renderPieChart();
  }
  radomColor() {
    return "#" + ((Math.random() * 0xffffff) << 0).toString(16);
  }
}
