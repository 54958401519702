import { VuexModule, Module, getModule, MutationAction } from "vuex-module-decorators"
import { RuleViolationChart } from "@/store/models/ruleViolationChart"
import RuleViolationChartAPI from "@/store/api/ruleViolationChart"
import store from "@/store"


@Module({
    namespaced: true,
    name: 'RuleViolation',
    store,
    dynamic: true
})
class RuleViolationChartModule extends VuexModule {
    chart: RuleViolationChart = {} as RuleViolationChart

    @MutationAction
    async ViolationChart(){
        const response = await RuleViolationChartAPI.getViolationChart();
        return { chart: response }
    }
}

export default getModule(RuleViolationChartModule)